<template>
  <div class="stand-order-list">
    <Action-panel :isReady="isPageReady"/>
    <TableList
      :is-ready="isPageReady"
      :headers="headers"
      :items="model"
      :searchVisible="false"
      :sortable="true"
      :hide-footer="true"
      :itemsPerPage="model.length"
      @sorted="sortedEvent"/>
  </div>
</template>


<script>
import Vue from "vue";
import Component from "vue-class-component";
import TableList from "@/components/table/TableList";
import ButtonBase from "@/components/dom/ButtonBase";
import ActionPanel from "@/components/panel/ActionPanel";
import { standService } from "@/services/standService.js";
import { toastService } from "@/services/toastService.js";

@Component({
  components: {
    TableList,
    ActionPanel,
    ButtonBase
  },
})

export default class PavilionStandOrder extends Vue {
  pavilionId = "";
  isPageReady = false;
  isSaving = false;
  model = [];

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.stand_table_displayOrder,
        value: "displayOrder",
      },
      {
        text: this.translations.labels.stand_table_code,
        value: "code",
      },
      {
        type: "multipleactions",
        sortable: false,
        value: "multipleactions",
        actions: [
          {
            icon: "mdi-format-align-top",
            value: "totop",
            onAction: (item) => {
              this.moveStandToTop(item);
            },
          },
          {
            icon: "mdi-format-align-bottom",
            value: "tobottom",
            onAction: (item) => {
              this.moveStandToBottom(item);
            },
          }
        ]
      }
    ];
  }

  async saveStandOrder() {
    await standService.updateOrder(this.model.map(x => ({
      Id : x.id,
      DisplayOrder : x.displayOrder
    })));
    this.model = await standService.list(this.pavilionId);
  }

  moveStand(oldIndex, newIndex){
    const movedItem = this.model
      .splice(oldIndex, 1)[0];
    this.model
      .splice(newIndex, 0, movedItem);
    this.model.map(x => x.displayOrder = this.model.indexOf(x));
    this.saveStandOrder();
  }

  moveStandToTop(stand){
    this.moveStand(this.model.indexOf(stand), 0);
  }

  moveStandToBottom(stand){
    this.moveStand(this.model.indexOf(stand), this.model.length);
  }

  sortedEvent(event){
    this.moveStand(event.oldIndex, event.newIndex);
  }

  async init(){
    this.pavilionId = this.$route.params.id;
    try {
      this.model = await standService.list(this.pavilionId);
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  mounted(){
    this.init();
  }

}
</script>

<style lang="scss" scoped>
</style>
